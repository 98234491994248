import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from '../components/seo'

import './blog.scss';

export default function Home({ data }) {
    //highlight-line
    return (
        <Layout>
            <Seo title="Shishir Singh's Blog Posts" />
            {/* highlight-start */}
            <div id="blog" className="container section blog">
                <div className="section-heading has-text-centered mb-6">
                    <h3 className="title is-2">Blog</h3>
                    <h4 className="subtitle is-5">Recent Posts</h4>
                </div>
                {data.allWpPost.nodes.map(node => (
                    <article key={node?.link} className="media">
                        <figure className="media-left">
                            <p className="image is-64x64">
                                <img alt={node.featuredImage?.node?.altText} src={node.featuredImage?.node?.mediaItemUrl} />
                            </p>
                        </figure>
                        <div className="media-content">
                            <div className="content">

                                <a aria-describedby={node.id} target="_blank" rel="noopener" href={`https://www.regardingtech.com${node.link}`}>
                                    <strong>{node.title}</strong>
                                </a>
                                <br />
                                <div id={node.id} dangerouslySetInnerHTML={{ __html: node.excerpt }} />

                            </div>
                        </div>
                    </article>
                ))}
            </div>
        </Layout>
    )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPost(limit: 6, sort: {fields: [date], order: DESC}) {
        nodes {
        id
        title
        link
        excerpt
        featuredImage {
            node {
                mediaItemUrl
                altText
            }
        }
      }
    }
  }
                                    `
//highlight-end
